import Reveal from "react-reveal/";
import { Link } from "react-router-dom";
import { appPaths } from "../../variables";
const Banner = () => {
  return (
    <section className="startup_banner_area_three">
      <div className="home_bubble">
        <div className="bubble b_one"></div>
        <div className="bubble b_two"></div>
        <div className="bubble b_three"></div>
        <div className="bubble b_four"></div>
        <div className="bubble b_five"></div>
        <div className="bubble b_six"></div>
        <div className="triangle b_seven" data-parallax='{"x": 20, "y": 150}'>
          <img src={require("../../img/triangle_one.png")} alt="" />
        </div>
        <div className="triangle b_eight" data-parallax='{"x": 120, "y": -10}'>
          <img src={require("../../img/triangle_two.png")} alt="" />
        </div>
        <div className="triangle b_nine">
          <img src={require("../../img/triangle_three.png")} alt="" />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Reveal bottom cascade duration={1100}>
              <div className="startup_content_three">
                <h2>
                  <span>We help couriers, retailers in Africa solve missed package delivery problems</span>
                </h2>
                <p>
                Eliminate the costs of rescheduled package delivery
                </p>
                <Link to={appPaths.partner} className="btn_six slider_btn">
                  Try for free
                </Link>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="stratup_app_screen">
        <Reveal effect="slideInnew">
          <img
            className="phone"
            src={require("../../img/new/banner/Ban.png")}
            alt=""
          />
        </Reveal>
        <Reveal effect="slideInnew">
          <img
            className="laptop chat_img "
            src={require("../../img/new/banner/banner-phone.png")}
            alt=""
          />
        </Reveal>
      </div>
    </section>
  );
};
export default Banner;
